import { reactive } from '@vue/composition-api'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import useCldUtil from '@/use/useCldUtil'

import { createFolder } from '@/helper/damUtils'
import { v4 as uuidv4 } from 'uuid'

/* eslint-disable-next-line */
export default (ctx) => {
  const formData = reactive({
    id: null,
    title: null,
    description: null,
    thumbnail: null,
    document: null,
    is_new: false,
    startDate: null,
    endDate: null,
    folder_name: null,
    updated_at: null,
    created_by: null,
    updated_by: null
  })
  const { setRequest: setCldRequest } = useCldUtil(ctx)

  const prepareFormData = (result) => {
    formData.id = result.id
    formData.revision_id = result.revision_id
    formData.title = result.title
    formData.description = result.description
    formData.is_new = isNil(result.is_new) ? false : result.is_new
    formData.startDate = result.start_cover_date
    formData.endDate = result.end_cover_date
    formData.thumbnail_id = result.thumbnail_id
    formData.file_id = result.file_id
    formData.folder_name = result.folder_name
    formData.updated_at = result.updated_at
    formData.created_by = result.created_by
    formData.updated_by = result.updated_by

    if (get(result, ['thumbnail', 'file_name'])) {
      formData.thumbnail = setCldRequest(result.thumbnail)
    }

    if (get(result, ['file', 'file_name'])) {
      formData.document = setCldRequest(result.file)
    }
  }

  const fetchByRouteId = async () => {
    const revision_id = get(ctx.root.$router.currentRoute.params, 'id', null)
    const folders = ['Cover', 'File']
    if (revision_id) {
      ctx.root.$vs.loading()
      ctx.root.$store
        .dispatch('magazine/fetchOne', revision_id)
        .then((result) => {
          prepareFormData(result)
          if (!formData.folder_name) {
            const randomUuid = uuidv4()
            formData.folder_name = randomUuid
          }
          return createFolder(`motivo/${formData.folder_name}`, folders)
        })
        .then(() => ctx.root.$vs.loading.close())
        .catch(() => {
          ctx.root.$vs.loading.close()
        })
    } else {
      const randomUuid = uuidv4()
      ctx.root.$vs.loading()
      formData.folder_name = randomUuid
      await createFolder(`motivo/${randomUuid}`, folders)
      ctx.root.$vs.loading.close()
    }
  }

  return {
    formData,
    prepareFormData,
    fetchByRouteId,
  }
}
