<template>
  <div 
    id="data-list-list-view" 
    class="data-list-container">
    <PopupLanguage
      v-model="isPopupDuplicateActive"
      :on-click-btn="handlerDuplicateContent"
      name="property-asset-project-create"
    />
    <custom-table
      ref="table"
      :max-items="itemsPerPage"
      :data="items"
      :searchKeys="searchKeys"
      pagination
      search
    >
      <div 
        slot="header" 
        class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <AddNewContent name="lh-content-motivo-create" />
        <LhDropdownPerPage 
          :items="items" 
          :items-per-page="itemsPerPage" 
          :current-page="currentPage" 
          :queried-items="queriedItems"
          @click="(page) => itemsPerPage = page" /> 
      </div>

      <template slot="thead">
        <vs-th>Thumbnail</vs-th>
        <vs-th sort-key="title">ชื่อนิตยสาร</vs-th>
        <vs-th sort-key="start_date_sort">ช่วงเวลา</vs-th>
        <vs-th sort-key="status">Status</vs-th>
        <vs-th sort-key="updated_at">Last Updated</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr 
            v-for="(tr, indextr) in data" 
            :data="tr" 
            :key="indextr">
            <td class="td vs-table--td" @click.stop="editData(tr.revision_id)">
              <img 
                :src="tr.thumbnail.file_url" 
                alt="img" 
                height="110" >
            </td>
            <td class="td vs-table--td" @click.stop="editData(tr.revision_id)">
              <p class="brand-name font-medium truncate">{{ tr.title }}</p>
            </td>
            <td>
              <p class="brand-priority">{{ tr.start_cover_date }} - {{ tr.end_cover_date }}</p>
            </td>
            <td class="td vs-table--td" @click.stop="editData(tr.revision_id)">
              <vs-chip 
                v-if="tr.is_new" 
                :color="'success'" 
                class="product-order-status">New</vs-chip>
            </td>
            <td class="td vs-table--td" @click.stop="editData(tr.revision_id)">
              <p class="brand-priority">{{ updatedAtDatetimeFormat(tr.updated_at) }}</p>
            </td>

            <vs-td class="whitespace-no-wrap cursor-default">
              <div class="flex space-x-2">
                <feather-icon
                  class="cursor-pointer"
                  icon="CopyIcon"
                  svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  @click.stop="duplicateContent(tr.revision_id)"
                />
                  <router-link
                    :to="{ name: `${routePrefix}-edit`, params: { id: tr.revision_id }}"
                    class="pt-2 color-text-link"
                  >
                  <feather-icon
                    icon="EditIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  />
                </router-link>
                <feather-icon
                  class="cursor-pointer"
                  icon="TrashIcon"
                  svg-classes="w-5 h-5 hover:text-danger stroke-current"
                  @click.stop="deleteData(tr.revision_id)"
                />
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </custom-table>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import map from 'lodash/map'
import moment from 'moment'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import AddNewContent from '@/components/AddNewContent'
import useMagazineUtil from '@/use/useMagazineUtil'
import PopupLanguage from '@/components/PopupLanguage'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import CustomTable from '@/components/CustomTable'

export default {
  components: { CustomTable, PopupLanguage, AddNewContent, LhDropdownPerPage },
  setup(props, ctx) {
    const crudFunction = useCrud(ctx, 'magazine')
    const { formData, prepareFormData } = useMagazineUtil(ctx)
    const { updatedAtDatetimeFormat } = useDatepicker()
    crudFunction.routePrefix.value = 'lh-content-motivo'
    const items = computed(() => {
      return map(crudFunction.result.value, (item) => {
        return {
          ...item,
          start_cover_date: moment(item.start_cover_date).format('MMM YYYY'),
          start_date_sort: moment(item.start_cover_date).format('YYYY MM'),
          end_cover_date: moment(item.end_cover_date).format('MMM YYYY'),
          created_at: moment(item.created_at).format('DD MMMM  YYYY, HH:mm:ss'),
        }
      })
    })

    const handlerDuplicateContent = (langToDuplicationContent) => {
      crudFunction.handlerDuplicateContent(
        formData,
        prepareFormData,
        langToDuplicationContent,
      )
    }

    return {
      ...crudFunction,
      items,
      handlerDuplicateContent,
      updatedAtDatetimeFormat,
      searchKeys: ['title']
    }
  },
}
</script>
